















































































































































































































































































import { Component, Prop } from 'vue-property-decorator'
import { formatTimestamp } from '@/utils/utils'
import ScaDialog from './ScaDialog.vue'
import VueBase from '@/VueBase'
import { ScaListObj } from './types'
import ScrollToTop from '@/components/scrollToTop/scrollToTop.vue'

@Component({ name: 'ScaList', components: { ScrollToTop, ScaDialog } })
export default class ScaList extends VueBase {
  @Prop() version: any
  @Prop() projectId: any
  private sortSelect(flag: any) {
    this.searchObj.sort = flag
    this.newSelectData()
  }
  private dialogInfo = {}
  private searchOrderList: any = [
    {
      label: '默认排序',
      value: null,
    },
    {
      label: '升序',
      value: true,
    },
    {
      label: '降序',
      value: false,
    },
  ]
  private debounceMyScroll: any
  private page = 1
  private pageSize = 10
  private alltotal: any = 0
  private dataEnd = false
  private tableData: Array<ScaListObj> = []
  private searchOptionsObj: any = {
    filterLicenses:[],
    filterLevel:[],
    language: [],
    level: [],
    license: [],
    orderOptions: [
      {
        label: this.$t('views.scaList.orderOptions.level'),
        value: 'level',
      },
      // {
      //   label: '许可证',
      //   value: 'license',
      // },
      // {
      //   label: this.$t('views.scaList.orderOptions.language'),
      //   value: 'language',
      // },
      {
        label: '漏洞数量',
        value: 'vul_count',
      },
    ],
  }
  private levelColor1(level: any) {
    switch (level) {
      case 1:
        return { label: '高', color: '#c62d29', bg: '#c62d2933' }
      case 2:
        return { label: '中', color: '#ed9e3e', bg: '#ed9e3e33' }
      case 3:
        return { label: '低', color: '#5bd1c7', bg: '#5bd1c733' }
      case 5:
        return { label: '提示', color: '#277fed', bg: '#277fed33' }
      default:
        return { label: '无风险', color: '#a4a4a4', bg: '#000' }
    }
  }

  private levelColor(level: any) {
    switch (level) {
      case 1:
        return { label: '高', color: '#DC2626', bg: '#FEE2E2' }
      case 2:
        return { label: '中', color: '#EA580C', bg: '#FFEDD5' }
      case 3:
        return { label: '低', color: '#D97706', bg: '#FEF3C7' }
      case 0:
        return { label: '无', color: '#64748B', bg: '#E2E8F0' }
    }
  }

  private searchObj: any = {
    language: [],
    level: [],
    keyword: '',
    license: [],
    project_id: undefined,
    order: '',
    sort: null,
  }

  created() {
    if (this.projectId) {
      this.searchObj.project_id = this.projectId
    }

    this.getTableData()
    this.scaSummary()
  }

  private multipleSelection: any = []

  private handleSelectionChange(val: any) {
    this.multipleSelection = val
  }

  filterHandler(value:any, row:any, column:any) {
    if (value.scaNames && value.scaNames.length >0) {
      this.searchObj.level = value.scaNames
    } else {
       this.searchObj.level = []
    }

    if (value.license && value.license.length >0) {
      this.searchObj.license = value.license
    } else {
      this.searchObj.license = []
    }
    this.getTableData(true)
  }

  private reset() {
    this.searchOptionsObj.license.map((item1: any) => {
      item1.cur = false
    })
    this.searchOptionsObj.level.map((item1: any) => {
      item1.cur = false
    })

    this.searchObj.language = []
    this.searchObj.level = []
    this.searchObj.order = ''
    this.searchObj.sort = null
    this.searchObj.license = []
    this.searchObj.keyword = ''
    if (this.$route.name !== 'appDetail/:pid') {
      this.searchObj.project_id = ''
    }
    this.newSelectData()
  }

  private kw = ''
  private async querySearchAsync(queryString: string, cb: any) {
    console.log(queryString)
    const res = await this.services.setting.searchProject({ name: queryString })
    if (res.status === 201) {
      const data = res.data.map((item: any) => {
        return {
          value: item.name,
          id: item.id,
        }
      })
      cb(data)
    }
  }

  private languageChange(val: string, stop: boolean) {
    if (stop) {
      return
    }
    this.searchObj.language = val
    this.newSelectData()
  }

  private levelChange(val: string, stop: boolean) {
    if (stop) {
      return
    }
    this.searchObj.level = val
    this.newSelectData()
  }

  private resetData() {
    this.page = 1
    this.tableData = []
    this.searchObj.level = []
    this.searchObj.order = ''
    this.searchObj.keyword = ''
    this.getTableData()
  }

  private newSelectData() {
    this.page = 1
    this.tableData = []
    if (!this.searchObj.order) this.searchObj.sort = null
    this.getTableData()
  }

  mounted() {
    // this.getTableData()
    // this.scaSummary()
  }

  handleSizeChange(val: number) {
    this.pageSize = val
    this.getTableData()
  }
  handleCurrentChange(val: number) {
    this.page = val
    this.getTableData()
  }
  formatTimestamp(val: string) {
    return formatTimestamp(val)
  }
  private searchTableData(item: any) {
    this.searchOptionsObj.level.map((item1: any) => {
      if (item1.level_id == item.level_id) {
        if (!item.cur) {
          const items: any = this.searchObj.level
          item1.cur = true
          items.push(item1.level_id)
          this.searchObj.level = items
        } else {
          item1.cur = false
          const items = this.searchObj.level
          const result = items.filter((item: any) => item != item1.level_id)
          this.searchObj.level = result
        }
        // return item1
      }
    })
    this.getTableData(true)
  }

  private searchTableLicenseData(item: any) {
    this.searchOptionsObj.license.map((item1: any) => {
      if (item1.license_id == item.license_id) {
        if (!item.cur) {
          const items: any = this.searchObj.license
          item1.cur = true
          items.push(item1.license_id)
          this.searchObj.license = items
        } else {
          item1.cur = false
          const items = this.searchObj.license
          const result = items.filter((item: any) => item != item1.license_id)
          this.searchObj.license = result
        }
      }
    })
    this.getTableData(true)
  }
  public async getTableData(flag?: undefined | boolean) {
    if (flag) {
      this.page = 1
    }
    let sort = 'asc'
    if (!this.searchObj.sort) {
      sort = 'desc'
    }
    const params: any = {
      page: this.page,
      page_size: this.pageSize,
      language_ids: this.searchObj.language,
      level_ids: this.searchObj.level,
      license_ids: this.searchObj.license,
      order: sort,
      project_id: Number(this.projectId),
      project_version_id: this.version,
    }
    if (this.searchObj.keyword) {
      params.keyword = this.searchObj.keyword
    }
    if (this.searchObj.order) {
      params.order_field = this.searchObj.order
    }

    if (!this.projectId) {
      delete params.project_id
    }
    this.loadingStart()
    const { status, data, msg, page } = await this.services.sca.scaList(params)
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.tableData = data
    this.alltotal = page.alltotal
    // if (data.length < this.pageSize) {
    //   this.alltotal = this.page * this.pageSize - 1
    // } else {
    //   this.alltotal = this.page * this.pageSize + 1
    // }
  }

  private boxHeight: any = ''
  private showAccor: boolean = false
  public async scaSummary() {
    const params: any = {
      keyword: this.searchObj.keyword,
    }
    if (this.projectId || this.version) {
      params.project_id = this.projectId
      params.version_id = this.version
    }
    this.loadingStart()
    const { status, data, msg } = await this.services.sca.scaSummary(params)
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    const filterLevel:any = []
    const filterLicenses:any =[]
    const levels: any = []
    const licenses: any = []
    data.level.map((item: any) => {
      levels.push({
        ...item,
        cur: false,
      })
      filterLevel.push({
        text:item.level,
        value:item.level_id
      })
    })
    data.license.map((item: any) => {
      licenses.push({
        ...item,
        cur: false,
      })
      filterLicenses.push({
        text:item.license,
        value:item.license_id
      })
    })
    this.searchOptionsObj.language = data.language
    // this.searchOptionsObj.level = data.level
    this.searchOptionsObj.level = levels
    this.searchOptionsObj.filterLevel = filterLevel
    // this.searchOptionsObj.license = data.license
    this.searchOptionsObj.license = licenses
    this.searchOptionsObj.filterLicenses = filterLicenses
    this.$nextTick(() => {
      if ((this.$refs.accordion as any)?.offsetHeight > 180) {
        this.boxHeight = '150px'
        this.showAccor = true
      } else {
        this.boxHeight = 'auto'
        this.showAccor = false
      }
      console.log(
        'this.$refs.accordion',
        (this.$refs.accordion as any)?.offsetHeight
      )
    })
  }

  private isExpand: boolean = false
  handleAccor() {
    this.isExpand = !this.isExpand
    this.boxHeight = this.isExpand ? 'auto' : '150px'
  }

  private dialogFlag = false
  private goDetail(row: any) {
    // this.dialogInfo = row
    // this.dialogFlag = true
    // this.$router.push(`/sca/scalistDetail/1/${row.id}`)
    console.log(row)
    if (this.projectId) {
      this.$router.push({
        path: `/project/packageDetail/1/${row.id}`,
        query: {
          projectId: this.projectId,
          package_name: row.package_name,
          package_version: row.version,
          language_id: row.language_id,
          versionId: this.version,
        },
      })
      return
    }
    this.$router.push({
      path: `/project/packageDetail/1/${row.id}`,
      query: {
        package_name: row.package_name,
        package_version: row.version,
        language_id: row.language_id,
      },
    })
    // window.open(route.href, '_blank')
  }
  projectNameSplit(name: string, total: number) {
    if (name.length > total) {
      return name.substring(0, total)
    }
    return name
  }
}
