













































import { Component, Prop } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import qs from 'qs'
import StacksList from './stacksList.vue'
import { formatTimestamp } from '@/utils/utils'

@Component({
  name: 'LinkList',
  components: {
    StacksList,
  },
})
export default class LinkList extends VueBase {
  @Prop() tree: any
  @Prop() aggr_id: any
  created() {
    this.tree.forEach((element: any) => {
      this.$set(element, 'open', false)
    })
  }

  private async openRow(row: any) {
    if (!row.next) {
      const res = await this.services.sca.vulProjectAssets({
        project_id: row.project_id,
        project_version_id: row.project_version_id,
        vul_id: this.aggr_id,
      })
      if (res.status === 201) {
        this.$set(row, 'open', !row.open)
        this.$set(row, 'next', res.data)
      }
      return
    }
    this.$set(row, 'open', !row.open)
  }

  private levelColor(level: any) {
    switch (level) {
      case '高危':
        return '#E56363'
      case '中危':
        return '#F49E0B'
      case '低危':
        return '#2F90EA'
      case '无风险':
        return '#ABB2C0'
      case '信息':
        return '#DBDBDB'
    }
  }

  private levelLabel(level: any) {
    switch (level) {
      case 2:
        return '危险函数执行'
      case 0:
        return '外部参数传入'
      case 1:
        return '数据调用传播'
    }
  }

  private bgColor(level_type: number) {
    switch (level_type) {
      case 1:
        return 'rgba(229, 99, 99, 0.1)'
      case 2:
        return 'rgba(244, 158, 11, 0.1)'
      case 3:
        return 'rgba(47, 144, 234, 0.1)'
      case 4:
        return 'rgba(172, 180, 196, 0.1)'
      case 5:
        return 'rgba(219, 219, 219, 0.1)'
    }
  }
}
