






































































































































































































































































































































import { Component, Prop } from 'vue-property-decorator'
import { formatTimestamp, getPassedTime } from '@/utils/utils'
import VueBase from '@/VueBase'
import request from '@/utils/request'
import qs from 'qs'
import Sync from '../vuln/components/sync.vue'
import LinkList from './components/linkList.vue'

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate',
])
@Component({
  name: 'VulnDetail',
  components: {
    Sync,
    LinkList,
  },
})
export default class VulnDetail extends VueBase {
  @Prop() dialogInfo!: any
  @Prop() language: any
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.meta.i18n === 'menu.projectDetail') {
      this.$store.dispatch('setRouteInfo', [])
    } else {
      this.$store.dispatch('setRouteInfo', ['ProjectIndex', 'ProjectDetail'])
    }
    next()
  }
  private sliderWarpContract = false
  private deleteDialogOpen = false
  private logDialogOpen = false
  fmtTime(time: any) {
    if (time) {
      if (`${time}`.length === 10) {
        time = window.parseInt(time) * 1000
      } else {
        time = +time
      }
      const data = new Date(time).getTime() / 1000
      return formatTimestamp(data)
    }
    return ''
  }

  async openUrl(url: any) {
    window.open(url)
  }

  get CVENUMBERS() {
    const res = []
    for (let key in this.assetVulDetail.base_info) {
      switch (key) {
        case 'cnnvd':
          res.push({
            label: this.assetVulDetail.base_info[key],
            link: `http://www.cnnvd.org.cn/web/xxk/ldxqById.tag?CNNVD=${this.assetVulDetail.base_info[key]}`,
          })
          break
        case 'cnvd':
          res.push({
            label: this.assetVulDetail.base_info[key],
            link: `https://www.cnvd.org.cn/flaw/show/${this.assetVulDetail.base_info[key]}`,
          })
          break
        case 'cve':
          res.push({
            label: this.assetVulDetail.base_info[key],
            link: `https://cve.mitre.org/cgi-bin/cvename.cgi?name=${this.assetVulDetail.base_info[key]}`,
          })
          break
        case 'cwe':
          const cwe =
            this.assetVulDetail.base_info[key] &&
            this.assetVulDetail.base_info[key][0]
          res.push({
            label: cwe,
            link: `https://cwe.mitre.org/data/definitions/${cwe}.html`,
          })
          break
      }
    }
    return res
  }

  private openWindow(link: string) {
    window.open(link)
  }

  private settingInte: any = []
  private assetVulDetail: any = {}

  private async changePage(page: any) {
    this.page = page
    // this.getAssetVulProjects()
  }

  private async summary() {
    const res = await this.services.vuln.summary()
    if (res.status === 201) {
      this.settingInte = res.data
    }
  }

  goBack = () => {
    this.$router.go(-1)
    // this.$router.push({
    //   name: 'vulnList',
    // })
  }
  private levelObj = {
    0: '严重',
    1: '高危',
    2: '中危',
    3: '低危',
    4: '无风险',
  }

  private levelColor(level: any) {
    switch (level) {
      case 0:
        return '#E56363'
      case 3:
        return '#E56363'
      case 2:
        return '#F49E0B'
      case 1:
        return '#2F90EA'
      case 4:
        return '#ABB2C0'
      case 5:
        return '#DBDBDB'
    }
  }

  private statusOptions: Array<any> = [
    {
      value: this.$t('views.vulnDetail.reported'),
      label: this.$t('views.vulnDetail.reported'),
    },
    {
      value: this.$t('views.vulnDetail.confirmed'),
      label: this.$t('views.vulnDetail.confirmed'),
    },
    {
      value: this.$t('views.vulnDetail.fixed'),
      label: this.$t('views.vulnDetail.fixed'),
    },
    {
      value: this.$t('views.vulnDetail.ignored'),
      label: this.$t('views.vulnDetail.ignored'),
    },
  ]

  private tableData: Array<any> = []
  private page = 1
  private keyword = ''
  private selectedId = 0
  private total = 0

  private orderOptions = [
    {
      label: this.$t('views.vulnList.orderOptions.type'),
      value: 'type',
    },
    {
      label: this.$t('views.vulnList.orderOptions.level'),
      value: 'level',
    },
    {
      label: this.$t('views.vulnList.orderOptions.url'),
      value: 'url',
    },
    {
      label: this.$t('views.vulnList.orderOptions.latest_time'),
      value: 'latest_time',
    },
    {
      label: this.$t('views.vulnList.orderOptions.first_time'),
      value: 'first_time',
    },
  ]

  private aggr_id: any = 0
  async init() {
    this.aggr_id = parseInt(this.$route.params.page)
    this.selectedId = parseInt(this.$route.params.id)
    await this.getAssetVulDetail()
    // await this.getAssetVulProjects()
    await this.summary()
  }

  async getAssetVulDetail() {
    const res = await this.services.sca.vulPackId({
      vul_id: this.dialogInfo.vul_id, //组件漏洞id
    })
    if (res.status !== 201) {
      this.$message.error(res.msg)
      return
    }
    this.assetVulDetail = res.data
  }

  private show_en_ref: boolean = true
  async getEnRef() {
    const { data, status } = await this.services.setting.getShowEnRef()
    if (status!==201) return
    this.show_en_ref = data.show_en_ref
  }

  async created() {
    this.init()
    this.getEnRef()
  }

  // private async changeStatus(val: any) {
  //   const res = await this.services.vuln.changeStatus({
  //     id: this.selectedId,
  //     status_id: val,
  //   })
  //   if (res.status === 201) {
  //     this.$message({
  //       type: 'success',
  //       message: res.msg,
  //       showClose: true,
  //     })
  //   } else {
  //     this.$message({
  //       type: 'error',
  //       message: res.msg,
  //       showClose: true,
  //     })
  //   }
  // }

  private async vulnDelete() {
    this.loadingStart()
    const { status, msg } = await this.services.vuln.vulnDelete(this.selectedId)
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
    } else {
      this.$message({
        type: 'success',
        message: msg,
        showClose: true,
      })
      this.$router.push('/vuln/vulnList')
    }
  }

  private assetVulProjects = []
  private numberPages = 10
  private async getAssetVulProjects() {
    const res = await this.services.sca.assetVulProjects(this.aggr_id, {
      page: this.page,
      keyword: this.keyword,
    })
    this.assetVulProjects = res.data
    this.total = res.page.alltotal
    this.numberPages = res.page.page_size
  }

  private logList: any = []
  async vullogBtn() {
    const res = await this.services.vuln.vullog(this.$route.query.id, '2')
    this.logList = res.status
    this.logDialogOpen = true
  }
  // exportVul() {
  //   var projectName = this.assetVulDetail.base_info.project_names[0]
  //   request
  //     .get(
  //       `project/export?vid=${this.selectedId}&pid=${this.vulnObj.vul.project_id}`,
  //       {
  //         responseType: 'blob',
  //       }
  //     )
  //     .then((res: any) => {
  //       if (res.type === 'application/json') {
  //         this.$message.error({
  //           message: this.$t('views.vulnDetail.exportFail') as string,
  //           showClose: true,
  //         })
  //       } else {
  //         const blob = new Blob([res], {
  //           type: 'application/octet-stream',
  //         })
  //         const link = document.createElement('a')
  //         link.href = window.URL.createObjectURL(blob)
  //         link.download = projectName + '.doc'
  //         link.click()
  //         this.$message.success({
  //           message: this.$t('views.vulnDetail.exportSuccess') as string,
  //           showClose: true,
  //         })
  //       }
  //     })
  //     .catch(() => {
  //       this.$message.error({
  //         message: this.$t('views.vulnDetail.exportFail') as string,
  //         showClose: true,
  //       })
  //     })
  // }

  private async recheckVul() {
    const params = {
      ids: this.selectedId.toString(),
    }
    const { status, msg } = await this.services.vuln.vulRecheck(params)
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
    } else {
      this.$message({
        type: 'success',
        message: msg,
        showClose: true,
      })
    }
  }
}
