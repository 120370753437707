





































import { Component, Prop } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import qs from 'qs'
import { formatTimestamp } from '@/utils/utils'

@Component({
  name: 'StacksList',
})
export default class StacksList extends VueBase {
  @Prop() tree: any
  created() {
    this.tree.forEach((element: any) => {
      this.$set(element, 'open', false)
    })
  }

  private levelColor(level: any) {
    switch (level) {
      case 1:
        return '#E56363'
      case 2:
        return '#F49E0B'
      case 3:
        return '#2F90EA'
      case 4:
        return '#ABB2C0'
      case 5:
        return '#DBDBDB'
    }
  }

  private levelLabel(level: any) {
    switch (level) {
      case 'sink':
        return '危险函数执行'
      case 'source':
        return '外部参数传入'
      case 'propagator':
        return '数据调用传播'
    }
  }

  private bgColor(level_type: number) {
    switch (level_type) {
      case 1:
        return 'rgba(229, 99, 99, 0.1)'
      case 2:
        return 'rgba(244, 158, 11, 0.1)'
      case 3:
        return 'rgba(47, 144, 234, 0.1)'
      case 4:
        return 'rgba(172, 180, 196, 0.1)'
      case 5:
        return 'rgba(219, 219, 219, 0.1)'
    }
  }
}
