































































































































































































import { Component, Prop } from 'vue-property-decorator'
import { formatTimestamp, downloadJson } from '@/utils/utils'
import VueBase from '@/VueBase'
import escape from 'markdown-escape'
import '@wangeditor/editor/dist/css/style.css'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import ShareDialog from '@/components/share/shareDialog.vue'

@Component({ name: 'VulnList', components: { Editor, Toolbar, ShareDialog } })
export default class VulnList extends VueBase {
  @Prop() item: any
  @Prop() settingInte: any
  @Prop() getTableData: any
  @Prop() source_type: any

  private toPathName(name: any) {
    this.$router.push({
      name: name,
    })
  }

  async deleteVuln() {
    this.$msgbox({
      showCancelButton: true,
      cancelButtonText: '取消',
      confirmButtonText: '删除',
      cancelButtonClass: "cancelButtonClass",
      confirmButtonClass: "delete-btn",
      showClose: false,
      dangerouslyUseHTMLString: true,
      message: `
        <div class="title">
          <i class="el-icon-warning icon"></i>
          确定删除所选应用漏洞?
        </div>
        <div class="tip">
          删除后不可恢复，请谨慎操作
        </div>
      `,
    }).then(async () => {
      const res = await this.services.vuln.vulListDelete({
        source_type: this.source_type,
        ids: String(this.item.id),
      })

      if (res.status !== 201) {
        this.$message({
          type: 'error',
          message: res.msg,
          showClose: true,
        })
      } else {
        this.$message({
          type: 'success',
          message: res.msg,
          showClose: true,
        })
      }
      this.getTableData(true)
    })
  }

  private inteRules = {
    obj: [{ required: true, message: '请选择项目', trigger: 'blur' }],
    title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
    type: [{ required: true, message: '请选择问题类型', trigger: 'blur' }],
    level: [{ required: true, message: '请选择问题等级', trigger: 'blur' }],
  }

  private typeOptions: any = []
  private priorityOtions: any = []
  private jiraOptions: any = {}
  private jiraduedate: any = {}
  private projectOtions: any = []

  private wang = {
    editor: null,
    html: '<p>hello</p>',
    toolbarConfig: {},
    editorConfig: { placeholder: '请输入内容...' },
    mode: 'default', // or 'simple'
  }

  private getSrc(type: any) {
    switch (type) {
      case '2':
        return {
          img: require('@/assets/GitLab.png'),
          title: 'GitLab',
          name: 'gitlab',
        }
      case '3':
        return {
          img: require('@/assets/Jira.png'),
          title: 'Jira',
          name: 'jira',
        }
      case '4':
        return {
          img: require('@/assets/chandao.png'),
          title: '禅道',
          name: 'chandao',
        }
      default:
        return {
          img: '',
          title: '',
        }
    }
  }

  private async openInte(type: string, item: any, url: any) {
    if (url) {
      window.open(url)
      return
    }
    this.activeType = type
    if (!this.settingInte.some((item: any) => item == this.activeType)) {
      this.inteFlag = true
      return
    }

    this.inteForm.title = ''
    this.inteForm.level = ''
    this.inteForm.obj = ''
    this.inteForm.type = ''
    this.inteForm.vul_id = item.id
    this.typeOptions = []
    this.priorityOtions = []
    this.loadingStart()
    const res = await this.services.vuln.integrationProject({
      integration_type: type,
    })
    this.loadingDone()
    const options: any = []
    if (res.status !== 201) {
      this.$message.error(res.msg)
      return
    }
    if (res.status === 201) {
      switch (type) {
        case '4':
          res.data = res.data.products
          break
        default:
          break
      }

      res.data &&
        res.data.forEach((item: any) => {
          switch (type) {
            case '2':
              if (item.issues_enabled) {
                options.push({
                  value: item.id,
                  label: item.name,
                })
              }
              break
            case '3':
              options.push({
                value: item.id,
                label: item.name,
              })
              break
            case '4':
              options.push({
                value: item.id,
                label: item.name,
              })
              this.typeOptions = [
                {
                  value: 'feature',
                  label: '功能',
                },
                {
                  value: 'interface',
                  label: '接口',
                },
                {
                  value: 'performance',
                  label: '性能',
                },
                {
                  value: 'experience',
                  label: '体验',
                },
                {
                  value: 'improve',
                  label: '改进',
                },
                {
                  value: 'other',
                  label: '其他',
                },
              ]
              this.priorityOtions = [
                {
                  value: '1',
                  label: '1',
                },
                {
                  value: '2',
                  label: '2',
                },
                {
                  value: '3',
                  label: '3',
                },
                {
                  value: '4',
                  label: '4',
                },
              ]
              break
          }
        })
    }

    this.projectOtions = options

    if (this.source_type === 2) {
      const res = await this.services.sca.assetVulDetail({
        aggr_id: item.id, //组件列表id
        vul_package_id: 1, //组件漏洞id
      })
      if (res.status !== 201) {
        this.$message.error(res.msg)
        return
      }
      const scaDetile = res.data
      //  <tr>
      //         <td colSpan="1" rowSpan="1">最近发现时间</td><td colSpan="1" rowSpan="1">${formatTimestamp(
      //           item.last_time
      //         )}</td>
      //       </tr>
      let html = `
      <table style="">
        <tbody>
          <tr>
          <th colSpan="1" rowSpan="1">属性</th>
          <th colSpan="1" rowSpan="1">内容</th>
          </tr>
          <tr>
            <td colSpan="1" rowSpan="1">组件</td><td colSpan="1" rowSpan="1">
            ${item.vul_name}
            </td>
          </tr>
          <tr>
            <td colSpan="1" rowSpan="1">关联项目</td><td colSpan="1" rowSpan="1">${item.pro_info
              .map((item: any) => {
                if (typeof item === 'string') {
                  return item
                } else {
                  return item.asset__project_name
                }
              })
              .join('、')}</td> 
          </tr>
          <tr>
            <td colSpan="1" rowSpan="1">首次出现时间</td><td colSpan="1" rowSpan="1"> ${formatTimestamp(
              item.create_time
            )}
            </td>
          </tr>
           <tr>
            <td colSpan="1" rowSpan="1">语言</td><td colSpan="1" rowSpan="1">${
              item.package_language
            }</td>
          </tr>
          <tr>
            <td colSpan="1" rowSpan="1">编号</td><td colSpan="1" rowSpan="1">${
              item.vul_number
            }</td>
          </tr>
           <tr>
            <td colSpan="1" rowSpan="1">危险等级</td><td colSpan="1" rowSpan="1">${
              item.level_id
            }</td>
          </tr>
           <tr>
            <td colSpan="1" rowSpan="1">漏洞类型</td><td colSpan="1" rowSpan="1">${
              item.type_name
            }</td>
          </tr>
            <tr>
            <td colSpan="1" rowSpan="1">漏洞详情</td><td colSpan="1" rowSpan="1">${
              scaDetile.base_info.vul_detail
            }</td>
          </tr>
            <tr>
            <td colSpan="1" rowSpan="1">修复建议</td><td colSpan="1" rowSpan="1">${
              scaDetile.poc_info.fix_plan
            }</td>
          </tr>
            <tr>
            <td colSpan="1" rowSpan="1">可利用性</td><td colSpan="1" rowSpan="1">
             ${scaDetile.base_info.have_article === 1 ? '存在分析文章' : ''}
                ${scaDetile.base_info.have_poc === 1 ? '存在可利用代码' : ''}
                ${
                  scaDetile.base_info.have_poc !== 1 &&
                  scaDetile.base_info.have_article !== 1
                    ? '不存在可利用代码'
                    : ''
                }
           </td>
          </tr>
        </tbody>
      </table>
    `
      // |最近发现时间|${formatTimestamp(item.last_time)}|
      if (type == '3') {
        const ava = `${
          scaDetile.base_info.have_article === 1 ? '存在分析文章' : ''
        }${scaDetile.base_info.have_poc === 1 ? '存在可利用代码' : ''}${
          scaDetile.base_info.have_poc !== 1 &&
          scaDetile.base_info.have_article !== 1
            ? '不存在可利用代码'
            : ''
        }`

        html = `
    || 属性 || 内容 ||
    |组件|${item.vul_name}|
    |关联项目|
    ${item.pro_info
      .map((item: any) => {
        if (typeof item === 'string') {
          return item
        } else {
          return item.asset__project_name
        }
      })
      .join('、')}|
    |首次出现时间| ${formatTimestamp(item.create_time)}|
    |语言|${item.package_language}|
    |编号|${item.vul_number}|
    |危险等级|${item.level_id}|
    |漏洞类型|${item.type_name}|
    |漏洞详情|${scaDetile.base_info.vul_detail}|
    |修复建议|${scaDetile.poc_info.fix_plan}|
    |可利用性|${ava}|`
      }

      this.loadingDone()
      this.inteFlag = true
      this.wang.html = html
      this.inteForm.title = item.vul_name
    } else {
      this.loadingStart()
      const detailRes = await this.services.vuln.getVulnDetail(item.id)
      this.loadingDone()
      const detail = detailRes.data
      const thisItem = {
        first_time: detail.vul.first_time,
        http_method: detail.vul.http_method,
        id: item.id,
        language: detail.vul.language,
        latest_time: detail.vul.latest_time,
        level: detail.vul.level,
        level_type: detail.vul.level_type,
        project_id: detail.vul.project_id,
        project_name: detail.vul.project_name,
        status: detail.vul.status as any,
        taint_position: detail.vul.taint_position,
        type: detail.vul.type,
        uri: detail.vul.uri,
        url: detail.vul.url,
      }
      let info = ''
      detail.graphs.forEach((item: any, index: any) => {
        info += `服务:${item.meta.url}\n`
        item.graph &&
          item.graph.forEach((i: any, k: any) => {
            const str = `${k + 1}、${i.type}:${i.node}\n方法:${
              i.method
            }\n代码：${i.code}\n`
            info += str.replaceAll('<', '&lt;')
          })
        info += `\nRequest：${detail.headers[index]?.req_header}`
      })

      let html = `
      <table style="">
        <tbody>
          <tr>
          <th colSpan="1" rowSpan="1">属性</th>
          <th colSpan="1" rowSpan="1">内容</th>
          </tr>
          <tr>
            <td colSpan="1" rowSpan="1">漏洞</td><td colSpan="1" rowSpan="1">${
              thisItem.uri
            }的${thisItem.http_method}出现${thisItem.type}位置:${
        thisItem.taint_position
      }</td>
          </tr>
          <tr>
            <td colSpan="1" rowSpan="1">严重度</td><td colSpan="1" rowSpan="1">${
              thisItem.level
            }</td>
          </tr>
          <tr>
            <td colSpan="1" rowSpan="1">漏洞类型</td><td colSpan="1" rowSpan="1">${
              thisItem.type
            }</td>
          </tr>
          <tr>
            <td colSpan="1" rowSpan="1">漏洞状态</td><td colSpan="1" rowSpan="1">${
              thisItem.status
            }</td>
          </tr>
           <tr>
            <td colSpan="1" rowSpan="1">漏洞URL</td><td colSpan="1" rowSpan="1">${
              detail.vul.url
            }</td>
          </tr>
          <tr>
            <td colSpan="1" rowSpan="1">产生时间</td><td colSpan="1" rowSpan="1">${formatTimestamp(
              thisItem.first_time
            )}</td>
          </tr>
           <tr>
            <td colSpan="1" rowSpan="1">漏洞描述</td><td colSpan="1" rowSpan="1">${escape(
              detail.strategy.desc
            )}</td>
          </tr>
           <tr>
            <td colSpan="1" rowSpan="1">修复建议</td><td colSpan="1" rowSpan="1">${
              escape(detail.strategy.repair_suggestion) || '暂无'
            }</td>
          </tr>
          <tr>
            <td colSpan="1" rowSpan="1">漏洞详情</td><td colSpan="1" rowSpan="1">${escape(
              info
            )}</td>
          </tr>
        </tbody>
      </table>
    `
      if (type == '3') {
        html = `
    || 属性 || 内容 ||
    |漏洞|${thisItem.uri}的${thisItem.http_method}出现${thisItem.type}位置:${
          thisItem.taint_position
        }|
    |严重度|${thisItem.level}|
    |漏洞类型|${thisItem.type}|
    |漏洞状态|${thisItem.status}|
    |产生时间|${formatTimestamp(thisItem.first_time)}|
    |漏洞描述|${escape(detail.strategy.desc)}|
    |修复建议|${escape(detail.strategy.repair_suggestion || '暂无')}|
    |漏洞详情|${escape(info)}|
    `
      }
      this.loadingDone()
      this.inteFlag = true
      this.wang.html = html
      this.inteForm.title = `${thisItem.uri}的${thisItem.http_method}出现${thisItem.type}位置:${thisItem.taint_position}`
    }
  }

  private async enterInte() {
    const inteForm: any = this.$refs.inteForm
    inteForm.validate(async (valid: any) => {
      if (valid) {
        this.inteForm.desc = this.wang.html
        const req: any = { vul_type: this.source_type }
        switch (this.activeType) {
          case '3':
            const wList = ['type', 'level', 'obj', 'title', 'desc', 'vul_id']
            const obj = {}
            for (let k in this.inteForm) {
              if (!wList.includes(k)) {
                // console.log(k)
                if (k == 'duedate') {
                  obj[k] = this.inteForm[k]
                } else if (this.jiraOptions[k].schema.type == 'array') {
                  obj[k] = [
                    {
                      id: this.inteForm[k],
                    },
                  ]
                } else {
                  obj[k] = {
                    id: this.inteForm[k],
                  }
                }
              }
            }
            req.update = {}
            req.fields = {
              summary: this.inteForm.title,
              description: this.inteForm.desc,
              issuetype: {
                id: this.inteForm.type,
              },
              priority: {
                id: this.inteForm.level,
              },
              project: {
                id: this.inteForm.obj,
              },
              ...obj,
            }
            req.vul_id = this.inteForm.vul_id
            break
          case '2':
            req.title = this.inteForm.title
            req.description = this.inteForm.desc
            req.label = this.inteForm.type
            req.project_id = this.inteForm.obj
            req.vul_id = this.inteForm.vul_id

            break
          case '4':
            req.title = this.inteForm.title
            req.product = this.inteForm.obj
            req.spec = this.inteForm.desc
            req.pri = this.inteForm.level
            req.category = this.inteForm.type
            req.vul_id = this.inteForm.vul_id

            break
          default:
            break
        }
        const res = await this.services.vuln.integrationIssue(
          {
            ...req,
          },
          this.activeType
        )

        if (res.status === 201) {
          this.inteForm = {
            title: '',
            level: '',
            obj: '',
            type: '',
            desc: '',
            vul_id: '',
          }
          this.wang.html = ''
          this.inteFlag = false
          this.$message.success(res.msg)
          this.getTableData(true)
          return
        }
        this.$message.error(res.mgs)
      }
    })
  }

  private onCreated(editor: any) {
    this.wang.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
  }

  private inteFlag = false
  private inteForm = {
    obj: '',
    title: '',
    type: '',
    level: '',
    desc: '',
    vul_id: '',
  }

  private async getMeta(e: any) {
    const { obj, title, vul_id } = this.inteForm
    this.inteForm = {
      obj: '',
      title: '',
      type: '',
      level: '',
      desc: '',
      vul_id: '',
    }
    this.inteForm.vul_id = vul_id
    this.inteForm.title = title
    this.inteForm.obj = obj
    if (this.activeType == 3) {
      this.loadingStart()
      const res = await this.services.vuln.integrationMeta({
        integration_type: this.activeType,
        projectIds: e,
      })
      this.loadingDone()
      const typeOptions: any = []
      if (res.status === 201) {
        res.data.projects[0].issuetypes.forEach((item: any) => {
          typeOptions.push({
            value: item.id,
            label: item.name,
            ...item,
          })
        })
        this.typeOptions = typeOptions
      }
    }
  }

  private async getPriority(type: string) {
    this.inteForm.level = ''
    const options = []
    if (this.activeType == '3') {
      this.typeOptions.some((item: any) => {
        if (item.id == type) {
          for (let key in item.fields) {
            if (
              item.fields[key].schema.system != 'project' &&
              item.fields[key].schema.system != 'issuetype' &&
              item.fields[key].schema.system != 'fixVersions' &&
              item.fields[key]['allowedValues'] &&
              item.fields[key]['allowedValues'].length
            ) {
              this.$set(this.jiraOptions, key, {
                schema: item.fields[key].schema,
                fieldsname: item.fields[key].name,
                list: item.fields[key]['allowedValues'],
              })
            } else if (item.fields[key].schema.system == 'duedate') {
              this.$set(this.jiraduedate, key, {
                schema: item.fields[key].schema,
                fieldsname: item.fields[key].name,
                fieldId: item.fields[key].fieldId,
              })
            }
          }
          return true
        }
      })
    }
  }

  beforeDestroy() {
    const editor: any = this.wang.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  }

  private activeType: any = ''

  private showShareDialog: boolean = false
  private handleShare() {
    this.showShareDialog = true
  }

  async handleDownload() {
    const { status, data } = await this.services.vuln.downloadMethodPool(this.item.id)
    if (status != 201) return
    downloadJson(data)
  }
}
